exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-index-js": () => import("./../../../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-design-patterns-elements-of-reusable-object-oriented-design-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/design-patterns-elements-of-reusable-object-oriented-design/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-design-patterns-elements-of-reusable-object-oriented-design-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-ditch-your-doorstop-adventures-in-rapid-prototyping-and-entrepreneurship-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/ditch-your-doorstop-adventures-in-rapid-prototyping-and-entrepreneurship/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-ditch-your-doorstop-adventures-in-rapid-prototyping-and-entrepreneurship-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-engineering-a-better-snake-vacuum-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/engineering-a-better-snake-vacuum/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-engineering-a-better-snake-vacuum-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-introduction-to-computer-science-and-programming-using-python-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/introduction-to-computer-science-and-programming-using-python/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-introduction-to-computer-science-and-programming-using-python-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-introduction-to-programming-with-python-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/introduction-to-programming-with-python/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-introduction-to-programming-with-python-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-object-oriented-design-design-patterns-and-software-architecture-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/object-oriented-design-design-patterns-and-software-architecture/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-object-oriented-design-design-patterns-and-software-architecture-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-perhaps-i-was-too-hard-on-racket-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/perhaps-i-was-too-hard-on-racket/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-perhaps-i-was-too-hard-on-racket-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-programming-languages-a-b-and-c-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/programming-languages-a-b-and-c/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-programming-languages-a-b-and-c-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-selling-3-d-assets-for-fun-and-profit-part-1-introduction-to-3-d-assets-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/selling-3d-assets-for-fun-and-profit-part-1-introduction-to-3d-assets/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-selling-3-d-assets-for-fun-and-profit-part-1-introduction-to-3-d-assets-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-systematic-program-design-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/systematic-program-design/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-systematic-program-design-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

